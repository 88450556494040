import { ComponentProps } from "react";
import { PanelGroup } from "react-resizable-panels";

import useAppDrawerStore from "components/AppDrawer/useAppDrawerStore";
import { RouteParams } from "components/routing/utils";
import useRouteChange from "hooks/useRouteChange";
import tw from "utils/tw";

import JoinRequest from "components/design-system/ui/JoinRequest/JoinRequest";
import Pane from "components/design-system/ui/Pane";
import GroupProfilePane from "components/design-system/ui/ProfilePane/GroupProfilePane";
import UserProfilePane from "components/design-system/ui/ProfilePane/UserProfilePane";
import ThreadCompose from "components/threads/ThreadCompose/ThreadCompose";
import ThreadPane from "components/threads/ThreadPane";
import InboxApps from "components/views/inbox/InboxViews/InboxApps";

import AppPanelResizeHandle from "./AppPanelResizeHandle";
import { Actions, Payload, parsePayload } from "./secondaryPaneUtils";

export enum PaneID {
  PRIMARY = "primary-pane",
  SECONDARY = "secondary-pane",
}

const paneMinWidth = "min-w-[max(25%,_240px)]";

const SplitView = ({
  children,
  d,
  messageID,
  rounded,
  threadID,
  userID,
}: WithChildren<
  RouteParams & {
    payload?: Payload;
  } & Pick<ComponentProps<typeof Pane>, "rounded">
>) => {
  const { closeDrawer, isOpen, payload } = useAppDrawerStore(
    ({ closeDrawer, isOpen, payload }) => ({
      closeDrawer,
      isOpen,
      payload,
    })
  );

  useRouteChange(() => {
    if (isOpen && payload) {
      closeDrawer();
    }
  });

  const secondaryPane = payload || (d ? parsePayload(d) : undefined);

  return (
    <PanelGroup autoSaveId="split" direction="horizontal">
      <Pane
        className={tw({ "w-1/2 shrink-0": secondaryPane }, paneMinWidth)}
        id={PaneID.PRIMARY}
        order={0}
        minSize={secondaryPane ? 25 : undefined}
        rounded={rounded}
      >
        {children ?? (
          <ThreadPane messageID={messageID} threadID={threadID ?? userID} />
        )}
      </Pane>

      {secondaryPane && (
        <>
          <AppPanelResizeHandle className="relative -left-2" />
          <Pane
            key={JSON.stringify(secondaryPane)} // ensures the pane is re-rendered when the payload changes
            className={tw("w-1/2 shrink-0", paneMinWidth)}
            id={PaneID.SECONDARY}
            order={1}
            rounded="all"
          >
            {payloadView(secondaryPane, true)}
          </Pane>
        </>
      )}
    </PanelGroup>
  );
};

function payloadView(payload: Payload, secondaryPane?: boolean) {
  switch (payload.action) {
    case Actions.APP:
      return <InboxApps appID={payload.appID} secondaryPane={secondaryPane} />;

    case Actions.REPLY:
      return (
        <ThreadCompose
          initialDraft={{ recipients: payload.initialRecipients }}
          replyToMessage={payload.message}
          secondaryPane={secondaryPane}
        />
      );

    case Actions.DRAFT:
      return (
        <ThreadCompose
          draftID={payload.threadID}
          secondaryPane={secondaryPane}
        />
      );

    case Actions.THREAD:
      return (
        <ThreadPane
          messageID={payload.messageID}
          secondaryPane={secondaryPane}
          threadID={payload.threadID}
        />
      );

    case Actions.JOIN:
      return <JoinRequest pane="secondary" joinID={payload?.joinID} />;

    case Actions.GROUP_PROFILE:
      return <GroupProfilePane pane="secondary" groupID={payload.groupID} />;

    case Actions.USER_PROFILE:
      return <UserProfilePane pane="secondary" userID={payload.userID} />;
  }
}

export default SplitView;
