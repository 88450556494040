import { useApolloClient } from "@apollo/client";
import { useCallback, useEffect, useRef } from "react";

import {
  FetchBoxFilePreviewDocument,
  FetchBoxFilePreviewQuery,
} from "generated/graphql";

const useGetFilePreviewData = () => {
  const client = useApolloClient();

  return useCallback(
    async (variables: {
      id: string;
    }): Promise<{ boxAccessToken: string; boxFileID: string }> => {
      const { data } = await client.query<FetchBoxFilePreviewQuery>({
        fetchPolicy: "network-only",
        query: FetchBoxFilePreviewDocument,
        variables,
      });
      return data.filePreview;
    },
    [client]
  );
};

const useGetBoxData = ({
  files,
}: {
  files: { id: string; title: string; url: string }[];
}) => {
  const boxIDs = useRef<Map<string, { boxId?: string; glueId: string }>>(
    new Map()
  );

  useEffect(() => {
    files.forEach(f => {
      const boxId = boxIDs.current.get(f.id)?.boxId;
      boxIDs.current.set(f.id, { boxId, glueId: f.id });
    });
  }, [files]);

  const getFilePreviewData = useGetFilePreviewData();
  const getBoxData = useCallback(
    (fileId: string) => {
      const fileIdsArray = [...boxIDs.current.values()];
      try {
        return Promise.all(
          [
            ...[
              ...fileIdsArray.splice(
                fileIdsArray.findIndex(({ glueId }) => glueId === fileId)
              ),
              ...fileIdsArray,
            ].map(({ glueId: glueId_1 }) => glueId_1),
          ].map(id =>
            getFilePreviewData({ id })
              .then(boxData => {
                boxIDs.current.set(id, {
                  boxId: boxData.boxFileID,
                  glueId: id,
                });
                return boxData;
              })
              .catch(error => {
                console.warn("Error: [FetchBoxFilePreviewQuery] -", error);
                return undefined;
              })
          )
        );
      } catch (error) {
        console.warn("Error: [attachment file] -", error);
        return [undefined];
      }
    },
    [getFilePreviewData]
  );

  return { boxIDs, getBoxData };
};

export default useGetBoxData;
