import { Children, type MouseEvent } from "react";
import { Link, useHistory } from "react-router-dom";

import { sanitizeUrl } from "@braintree/sanitize-url";

import { MentionSearchName } from "components/MessageEditor/types";
import {
  AppOrigin,
  routePath,
  routeToGroup,
  routeToThread,
  routeToUser,
} from "components/routing/utils";
import useAuthData from "hooks/useAuthData";
import { matchURL } from "utils/matchURL";
import tw from "utils/tw";

import { RecipientTooltip } from "components/RecipientTooltip";

import { Styles } from ".";

const mentionRegex = /^glue:(thr|grp|usr|wks)_/g;

type Props = {
  className?: string;
  reactTestId?: string;
  url: string;
  handleMasonryLink?: (url: string) => void;
};

const masonryLink = /^masonry:[a-zA-Z0-9/_-]*$/i;

const Hyperlink = ({
  children,
  className: classNameProp,
  reactTestId,
  url: urlProp,
  handleMasonryLink,
}: WithChildren<Props>): JSX.Element | null => {
  const { authData } = useAuthData();
  const history = useHistory();

  const isMention = urlProp.match(mentionRegex) !== null;
  const isOwnMention = urlProp === `glue:${authData?.me.id}`;

  const className = {
    link: tw(
      Styles.hyperLink,
      { [Styles.mention]: isMention },
      { [Styles.mentionOwn]: isOwnMention },
      {
        [Styles.mentionUser]: urlProp.startsWith("glue:usr_"),
      },
      classNameProp
    ),
  };

  if (urlProp.match(masonryLink) && handleMasonryLink) {
    const onClick = (e: MouseEvent) => {
      e.preventDefault();
      handleMasonryLink(urlProp);
    };
    return (
      <a
        className={className.link}
        data-testid={reactTestId}
        onClick={onClick}
        href={urlProp}
      >
        {children}
      </a>
    );
  }

  const canonicalUrl = urlProp.replace(
    mentionRegex,
    `${window.location.origin}/$1_`
  );
  const url = isMention ? canonicalUrl : urlProp;

  const matchedURL = matchURL(url);

  if (!matchedURL) return <>{Children.map(children, child => child)}</>;

  const { origin, pathname, search } = new URL(matchedURL);

  const handleMentionClick = (e: MouseEvent) => {
    e.preventDefault();
    urlProp.startsWith("glue:thr")
      ? history.push(
          routeToThread({
            threadID: urlProp.replace(/^glue:(thr)_/g, "$1_"),
            to: "secondary",
          })
        )
      : urlProp.startsWith("glue:usr")
        ? history.push(
            routeToUser({
              to: "secondary",
              userID: urlProp.replace(/^glue:(usr)_/g, "$1_"),
            })
          )
        : urlProp.startsWith("glue:grp")
          ? history.push(
              routeToGroup({
                to: "secondary",
                groupID: urlProp.replace(/^glue:(grp)_/g, "$1_"),
              })
            )
          : history.push(
              routePath({
                recipientID: urlProp.replace(/^glue:(wks)_/g, "$1_"),
              })
            );
  };

  const internalLink = (
    <Link
      className={className.link}
      data-testid={reactTestId}
      onClick={isMention ? handleMentionClick : undefined}
      to={pathname + search}
    >
      {children}
    </Link>
  );

  if (isMention) {
    const recipientID = urlProp.replace("glue:", "");
    return (
      <RecipientTooltip
        elementDOMAttributes={{
          "data-mention-atom-id": recipientID,
          "data-mention-atom-name": MentionSearchName.All,
        }}
        id={recipientID}
        clickEnabled
      >
        {internalLink}
      </RecipientTooltip>
    );
  }

  const isGlueDomain = `${origin}` === AppOrigin;
  if (isGlueDomain) return internalLink;

  return (
    <a
      className={className.link}
      data-testid={reactTestId}
      href={sanitizeUrl(url)}
      rel="noreferrer"
      target="_blank"
    >
      {children}
    </a>
  );
};

export default Hyperlink;
