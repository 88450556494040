import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router";

import { useApolloClient } from "@apollo/client";
import { differenceBy, isEqual, maxBy, partition, uniqBy } from "lodash-es";
import { Flipper } from "react-flip-toolkit";

import { ThreadEdgeSimple } from "@utility-types";
import { readThreadEdge } from "apollo/cache/threadHelpers";
import BulkEditActionBar from "components/design-system/ui/BulkEditActionBar";
import MoreLessButton from "components/design-system/ui/sections-sidebar/MoreLessButton";
import { usePartitionState } from "components/routing/RoutingPartition";
import {
  ThreadsTabs,
  routeParams as getRouteParams,
  locationFromRoute,
  routeToThread,
  tabPath,
} from "components/routing/utils";
import useListAutoSelect from "components/thread/ThreadList/hooks/useListAutoSelect";
import useThreadSelection from "components/thread/ThreadList/hooks/useThreadSelection";
import { useThreadListData } from "components/threads-list/hooks";
import { ThreadsMailbox, ThreadsOrder } from "generated/graphql";
import usePrevious from "hooks/usePrevious";
import useLocalSettingsStore from "store/useLocalSettingsStore";
import useUnreadSidebarItemStore from "store/useUnreadSidebarItemStore";

import { Portal } from "components/Portal";
import { SIDEBAR_ACCESSORY_CONTAINER_ID } from "components/design-system/ui/SidebarAccessoryContainer";
import useAppStateStore from "store/useAppStateStore";
import breakpoints from "utils/breakpoints";
import env from "utils/processEnv";
import FollowingThreadEdges from "./FollowingThreadEdges";
import InboxSkeletons from "./InboxSkeletons";

import { Dropdown } from "components/design-system/FloatingUi";
import { DropdownActionButton } from "components/design-system/FloatingUi/DropdownActionButton";
import { DropdownActionButtonGroup } from "components/design-system/FloatingUi/DropdownActionButtonGroup";
import { DropdownActions } from "components/design-system/FloatingUi/DropdownActions";
import { SectionHeaderTitleButton } from "./SectionHeaderTitleButton";

const FollowingThreadsList = ({
  initialMaxShownEdges = 10,
  skipAutoSelect = false,
}: {
  initialMaxShownEdges?: number;
  skipAutoSelect?: boolean;
}) => {
  const [isOpen, setOpen] = useState(false);
  const history = useHistory();
  const cache = useApolloClient().cache;

  const { currentThreadSort } = useLocalSettingsStore(
    ({ currentThreadSort }) => ({ currentThreadSort })
  );
  const previousThreadSort = usePrevious(currentThreadSort || null);
  const updateThreadSort = currentThreadSort !== previousThreadSort;

  const { route } = usePartitionState(({ route }) => ({ route }));
  const routeParams = getRouteParams(locationFromRoute(route));
  const {
    appID,
    threadID: selectedID,
    view,
    recipientID,
    userID,
  } = routeParams;

  // INBOX LIST
  // ====

  const threadListData = useThreadListData({
    excludeChats: true,
    excludeStarred: true,
    mailbox: ThreadsMailbox.Inbox,
    pageSize: initialMaxShownEdges * 2 - 1, // allows for archiving without fetching more
  });

  const {
    loadingNextPage,
    loadNextPage,
    refresh,
    result: { threadEdges, totalCount = 0 },
  } = threadListData;

  const inboxHasData = !!threadEdges;
  const [inboxReady, setInboxReady] = useState(inboxHasData);
  const [maxShownEdges, setMaxShownEdges] = useState(initialMaxShownEdges);

  const [inboxThreadEdges, setInboxThreadEdges] = useState<ThreadEdgeSimple[]>(
    threadEdges?.slice(-initialMaxShownEdges) ?? []
  );
  const removedThreadEdges = useRef<ThreadEdgeSimple[]>([]);

  // stores a record of inboxThreadEdges when inbox is closed;
  // can be used for comparison when managing inboxThreadEdges
  const prevInboxEdges = useRef<ThreadEdgeSimple[]>(inboxThreadEdges);

  const handleUpdateInbox = useCallback(
    (edges = threadEdges, numShownEdges = maxShownEdges) => {
      if (!edges) return;
      const nextThreadEdges = edges.slice(-numShownEdges);
      prevInboxEdges.current = nextThreadEdges;
      removedThreadEdges.current = [];
      setInboxThreadEdges(nextThreadEdges);
    },
    [maxShownEdges, threadEdges]
  );
  const handleUpdateInboxRef = useRef(handleUpdateInbox);
  handleUpdateInboxRef.current = handleUpdateInbox;

  const refreshInbox = useCallback(
    (update = true, numShownEdges?: number) =>
      refresh().then(
        ({ data: { threads } }) =>
          update && handleUpdateInbox(threads.edges, numShownEdges)
      ),
    [handleUpdateInbox, refresh]
  );
  const refreshInboxRef = useRef(refreshInbox);
  refreshInboxRef.current = refreshInbox;

  // update inbox when thread sort changes
  useEffect(() => {
    if (updateThreadSort) refreshInboxRef.current();
  }, [updateThreadSort]);

  // update inbox in realtime when app status is "inactive"
  const { appStatus } = useAppStateStore(({ appStatus }) => ({ appStatus }));
  useEffect(() => {
    if (appStatus === "active" || !inboxReady) return;
    handleUpdateInboxRef.current(threadEdges);
  }, [appStatus, inboxReady, threadEdges]);

  // show latest inbox data on mount
  useEffect(() => {
    if (!inboxHasData) return;

    if (!inboxReady) {
      handleUpdateInbox();
      setInboxReady(true);
    }
  }, [handleUpdateInbox, inboxHasData, inboxReady]);

  const navigateToWelcomeToGlueThread = useCallback(
    ({ edges }: { edges: ThreadEdgeSimple[] }) => {
      const welcomeToGlueThread = edges.find(
        e =>
          e.node.subject === "Welcome to Glue 🎉" &&
          e.node.recipients.edges.find(r => r.node.id === env.glueAIBotID)
      );
      if (
        !welcomeToGlueThread ||
        !welcomeToGlueThread.unreadMessageCounts.total
      )
        return;
      history.replace(
        routeToThread({
          superTab: "inbox",
          threadID: welcomeToGlueThread.node.id,
          to: "primary",
        })
      );
    },
    [history]
  );

  useEffect(() => {
    // - if we archive a message, remove it from the list;
    // - if property (isRead, etc.) changes, update that item in the list;
    if (appStatus === "inactive" || !inboxReady || !threadEdges) return;

    const availableEdges = threadEdges.slice(-maxShownEdges);

    if (!isEqual(prevInboxEdges.current, availableEdges)) {
      // find threads removed since the previous update
      const missingEdgeIDs = new Set(
        prevInboxEdges.current
          .filter(e => !threadEdges.find(e2 => e2.id === e.id))
          .map(e => e.id)
      );

      const newEdgeIDs = new Set(
        threadEdges
          .filter(e => !prevInboxEdges.current.find(e2 => e2.id === e.id))
          .map(e => e.id)
      );

      // find threads restored (not updated) since the previous update
      const [restoredEdges, removedEdges] = partition(
        removedThreadEdges.current,
        ({ id, cursor }) =>
          (threadEdges.find(e => e.id === id)?.cursor ?? "") === cursor
      );
      removedThreadEdges.current = removedEdges;

      // keep any incoming updated threads that were already visible,
      // along with any existing threads that should still be visible,
      // keeping in the same order they were before.
      const updatedEdges: ThreadEdgeSimple[] = [];
      prevInboxEdges.current.forEach(edge => {
        const updatedEdge =
          availableEdges.find(e => e.id === edge.id) ??
          readThreadEdge(edge.id, cache);
        if (!updatedEdge) return;

        const removed =
          updatedEdge.isArchived ||
          updatedEdge.isStarred ||
          missingEdgeIDs.has(updatedEdge.id);

        if (!removed) {
          updatedEdges.push(updatedEdge);
        } else {
          removedThreadEdges.current.push(updatedEdge);
        }
      });

      // find new and newly updated threads
      const latestUpdated = maxBy(updatedEdges, "cursor")?.cursor ?? "";
      const newEdges = availableEdges.filter(
        ({ cursor }) => cursor >= latestUpdated || newEdgeIDs.has(cursor)
      );

      // merge restored and updated, inserting into correct positions
      let [olderEdges, toMerge] = partition(
        restoredEdges.map(e => {
          const edge = availableEdges.find(({ id }) => id === e.id);

          if (edge) {
            return {
              ...e,
              isRead: edge.isRead,
              isArchived: edge.isArchived,
              isStarred: edge.isStarred,
            };
          }
          return e;
        }),
        edge => updatedEdges.every(e => e.cursor > edge.cursor)
      );
      for (const edge of updatedEdges) {
        let mergeHere: ThreadEdgeSimple[];
        [mergeHere, toMerge] = partition(toMerge, e => e.cursor <= edge.cursor);
        olderEdges.push(...mergeHere, edge);
      }

      // combine restored, updated, and new threads and
      // fill in up to maxShownEdges with more new ones.
      let nextEdges = uniqBy([...olderEdges, ...newEdges], "id");
      const diffToMax = Math.max(0, maxShownEdges - nextEdges.length);
      if (diffToMax > 0) {
        const newestCursor = maxBy(nextEdges, "cursor")?.cursor ?? "";
        let [otherNewEdges, otherOldEdges] = partition(
          differenceBy(threadEdges, nextEdges, "id"),
          ({ cursor }) => cursor > newestCursor
        );

        // fill in up to maxShownEdges with threads newer than first one
        otherNewEdges = otherNewEdges.slice(-diffToMax);

        // fill in up to maxShownEdges at the end with older threads
        const fromOld = Math.max(0, diffToMax - otherNewEdges.length);
        otherOldEdges = fromOld > 0 ? otherOldEdges.slice(-fromOld) : [];

        nextEdges = [...otherOldEdges, ...nextEdges, ...otherNewEdges];

        if (
          threadEdges.length < maxShownEdges + 1 &&
          threadEdges.length < totalCount &&
          maxShownEdges === initialMaxShownEdges
        ) {
          // fetch more threads in the background for next archive
          refreshInboxRef.current(false);
        }
      }

      prevInboxEdges.current = nextEdges;
      setInboxThreadEdges(nextEdges);

      newEdges.length && navigateToWelcomeToGlueThread({ edges: newEdges });
    }
  }, [
    appStatus,
    cache,
    inboxReady,
    initialMaxShownEdges,
    maxShownEdges,
    navigateToWelcomeToGlueThread,
    threadEdges,
    totalCount,
  ]);

  const inboxShownItems = useMemo(
    () => inboxThreadEdges.slice().reverse(),
    [inboxThreadEdges]
  );

  // THREAD SELECTION

  const inboxThreadSelection = useThreadSelection({
    excludeChats: true,
    excludeStarred: true,
    mailbox: ThreadsMailbox.Inbox,
    threadEdges: inboxThreadEdges,
  });

  const bulkEditActions = (() => {
    return (
      !!inboxThreadSelection.selection && (
        <BulkEditActionBar
          clearExcludedIDs={inboxThreadSelection.clearExcludedIDs}
          selection={inboxThreadSelection.selection}
          setSelectMode={inboxThreadSelection.setSelectMode}
          totalCount={totalCount || 0}
          canArchive
        />
      )
    );
  })();

  // NAVIGATION

  const navigateToThreadID = (threadID?: string, replace?: boolean) => {
    if (!threadID) return;

    const path = routeToThread({ superTab: "inbox", threadID, to: "primary" });
    replace ? history.replace(path) : history.push(path);
  };

  useListAutoSelect({
    edges: inboxThreadEdges,
    selectedID: selectedID ?? appID,
    selectID: id => navigateToThreadID(id, true),
    skip: skipAutoSelect || !!view || !!recipientID || !!userID,
  });

  // More / Less button

  const { resetUnreadItems } = useUnreadSidebarItemStore();

  const handleLess = useCallback(() => {
    if (!threadEdges) return;
    const nextThreadEdges = threadEdges.slice(-initialMaxShownEdges);
    prevInboxEdges.current = nextThreadEdges;
    removedThreadEdges.current = [];
    setInboxThreadEdges(nextThreadEdges);
    setMaxShownEdges(initialMaxShownEdges);

    refreshInboxRef.current(true, initialMaxShownEdges);
    resetUnreadItems("above");
  }, [initialMaxShownEdges, resetUnreadItems, threadEdges]);

  const handleMore = useCallback(() => {
    if ((threadEdges?.length ?? 0) > maxShownEdges) {
      setMaxShownEdges(prev =>
        Math.min(prev + initialMaxShownEdges, totalCount)
      );
      return;
    }

    loadNextPage(initialMaxShownEdges - 1).then(data => {
      const edges = data?.data?.threads?.edges ?? [];
      if (edges.length === 0) return;
      setMaxShownEdges(prev =>
        Math.min(prev + initialMaxShownEdges, totalCount)
      );
    });
  }, [
    initialMaxShownEdges,
    loadNextPage,
    maxShownEdges,
    threadEdges?.length,
    totalCount,
  ]);

  const hasMore = inboxShownItems.length < totalCount;

  // RENDER
  return (
    <div className="flex flex-col z-0" data-testid="following-wrapper">
      {breakpoints().md && bulkEditActions ? (
        bulkEditActions
      ) : (
        <div className="flex items-center h-32 px-12">
          <Dropdown
            setOpen={setOpen}
            content={
              <DropdownActions>
                <DropdownActionButtonGroup title="Sort">
                  <DropdownActionButton
                    icon="Clock"
                    selected={currentThreadSort === ThreadsOrder.LastMessage}
                    onClick={() => {
                      useLocalSettingsStore.setState({
                        currentThreadSort: ThreadsOrder.LastMessage,
                      });
                    }}
                  >
                    Recent first
                  </DropdownActionButton>
                  <DropdownActionButton
                    icon="Unread"
                    selected={currentThreadSort === ThreadsOrder.Unread}
                    onClick={() => {
                      useLocalSettingsStore.setState({
                        currentThreadSort: ThreadsOrder.Unread,
                      });
                    }}
                  >
                    Unread first
                  </DropdownActionButton>
                </DropdownActionButtonGroup>
                <DropdownActionButtonGroup>
                  <DropdownActionButton
                    icon="ArrowRightCircle"
                    onClick={() => {
                      history.push(
                        tabPath(ThreadsTabs.Following, { superTab: "threads" })
                      );
                    }}
                  >
                    See all
                  </DropdownActionButton>
                </DropdownActionButtonGroup>
              </DropdownActions>
            }
          >
            <SectionHeaderTitleButton isOpen={isOpen}>
              Threads
            </SectionHeaderTitleButton>
          </Dropdown>
          {/* // Keeping for now, until we prove that we don't need it anymore
          {inboxReady && threadEdges && (
            <FollowingUpdateButton
              inboxShownEdges={inboxShownItems}
              newEdges={threadEdges}
              update={handleUpdateInbox}
            />
          )} */}
        </div>
      )}

      <Flipper
        key={inboxReady ? "ready" : "loading"}
        element="ol"
        className="overflow-x-hidden"
        flipKey={inboxShownItems.map(e => e.id).join()}
      >
        <FollowingThreadEdges
          bulkSelect={inboxThreadSelection}
          bulkSelectEnabled={!!inboxThreadSelection.selection}
          flipIdPrefix="following-"
          inboxHasData={inboxHasData}
          initialShownEdges={
            maxShownEdges === initialMaxShownEdges
              ? Math.max(initialMaxShownEdges, inboxShownItems.length)
              : initialMaxShownEdges
          }
          onClickLess={handleLess}
          sectionIsOpen={true}
          threadEdges={inboxShownItems}
          canArchive
        />

        <InboxSkeletons count={5} on={!inboxReady} />

        {(threadEdges?.length ?? 0) > initialMaxShownEdges && (
          <MoreLessButton
            hasMore={hasMore}
            loading={loadingNextPage}
            onClick={hasMore ? handleMore : handleLess}
          />
        )}
      </Flipper>

      {!breakpoints().md && bulkEditActions && (
        <Portal id={SIDEBAR_ACCESSORY_CONTAINER_ID}>
          <div
            className="absolute bottom-12 inset-x-16 z-3"
            data-testid="bulk-select-actions-for-mobile"
          >
            {bulkEditActions}
          </div>
        </Portal>
      )}
    </div>
  );
};

export default FollowingThreadsList;
