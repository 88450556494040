import { useRef } from "react";
import { useFormContext } from "react-hook-form";

import { ReactComponent as GlueLogoDark } from "assets/glue/glue-logo-color-dark.svg";
import { ReactComponent as GlueLogoWhite } from "assets/glue/glue-logo-color-white.svg";
import ChoosePlanDropdown from "components/Payment/ChoosePlanDropdown";
import Payment from "components/Payment/Payment";
import Avatar from "components/design-system/Avatar/Avatar";
import { Button } from "components/design-system/Button";
import { Form } from "components/design-system/Forms";
import { InformationBubble } from "components/design-system/InformationBubble";
import ThemedImage from "components/helper/ThemedImage";
import { useWorkspaceSubscriptionPlansQuery } from "generated/graphql";
import useAuthData from "hooks/useAuthData";
import useElementBreakpoint from "hooks/useElementBreakpoint";
import useOnboardingStore from "store/useOnboardingStore";
import tw from "utils/tw";
import Footer from "./Footer";

const FormContent = () => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const { authData, authReady } = useAuthData();
  const { workspace, setState, currentStep } = useOnboardingStore(
    ({ workspace, setState, currentStep }) => ({
      workspace,
      setState,
      currentStep,
    })
  );

  const isWideView = useElementBreakpoint(containerRef.current, 950);

  const { data: plansData } = useWorkspaceSubscriptionPlansQuery({
    fetchPolicy: authReady ? "cache-and-network" : "cache-only",
    nextFetchPolicy: "cache-and-network",
    skip: !authData?.me.id,
  });

  const { watch } = useFormContext();
  const planID = watch("plan");

  const onComplete = () => {
    setState({
      view: "ChooseApps",
      totalSteps: 5,
      userSubscribed: true,
    });
  };

  return (
    <>
      <div className="absolute top-0 left-0 w-full h-full bg-background-app-base py-50 px-100 z-0" />
      <div
        ref={containerRef}
        className="flex overflow-hidden w-full z-1 bg-background-app"
      >
        <div
          className={tw(
            "flex flex-col items-center grow min-w-[420px] w-full py-32 overflow-hidden",
            isWideView ? "px-100" : "px-20"
          )}
        >
          <ThemedImage
            className="shrink-0 h-50 my-32"
            LightImage={GlueLogoWhite}
            DarkImage={GlueLogoDark}
          />
          <div className="flex flex-col w-full max-w-[600px] px-56 py-32 gap-16 bg-background-body border-1 border-border-container rounded-[24px] shadow-level2 overflow-y-auto">
            <div className="flex items-center justify-between">
              <div className="flex items-center text-text-body text-16 font-medium overflow-hidden">
                <Avatar
                  avatarURL={workspace?.avatarURL}
                  name={workspace?.name}
                  rounded="rounded-md"
                  size="medium"
                />
                <span className="text-body-bold ml-10 truncate">
                  {workspace?.name}
                </span>
              </div>
              <span className="text-caption text-text-subtle">Workspace</span>
            </div>
            <div className="flex flex-col">
              <span className="text-body-bold mb-4">Choose a plan</span>
              <ChoosePlanDropdown
                plans={plansData?.workspaceSubscriptionPlans}
              />
            </div>
            <div className="flex flex-col">
              <span className="text-body-bold">
                Try Glue risk free for 21 days, cancel anytime.
              </span>
              <ul className="list-disc list-inside text-body text-text-secondary mt-4">
                <li>Workspace for your entire team</li>
                <li>Unlimited threads, groups and message history</li>
                <li>Create custom AI-generated emojis</li>
                <li>Limited Glue AI usage included</li>
                <li>All supported app integrations</li>
                <li>Apps for Mac, iOS, and Android</li>
                <li>
                  Full support for top AI models (GPT-4, Claude, and more)
                </li>
              </ul>
            </div>
            <InformationBubble>
              <div className="flex justify-between items-center w-full">
                <span className="text-footnote">Want to learn more?</span>
                <Button
                  className="h-28"
                  buttonStyle="secondary"
                  onClick={() =>
                    window.open(
                      "https://calendly.com/d/ckhb-vmg-wm4/glue-product-onboarding",
                      "_blank"
                    )
                  }
                >
                  Book a demo
                </Button>
              </div>
            </InformationBubble>
          </div>
          <div className="flex flex-col grow justify-end w-full max-w-[600px]">
            <Footer
              onClickBack={() =>
                setState({
                  view: "CreateWorkspace",
                  currentStep: currentStep - 1,
                })
              }
              fullHeight={false}
              hideSkip
            >
              {null}
            </Footer>
          </div>
        </div>
        <div className="flex flex-col shrink-0 w-[485px] px-24 py-32 overflow-y-auto bg-background-white">
          <Payment
            workspaceID={workspace?.id}
            planID={planID ?? ""}
            onComplete={onComplete}
          />
        </div>
      </div>
    </>
  );
};

const PlanAndPayment = () => (
  <Form className="flex h-full min-w-[840px] mx-100 my-50 rounded-[32px] bg-background-subtle overflow-hidden">
    <FormContent />
  </Form>
);

export default PlanAndPayment;
