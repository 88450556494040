import { ModalProps } from "components/ModalKit/Modal";
import { FullScreenOverlay } from "components/Modals";
import tw from "utils/tw";

const AudioModal = ({
  audioURL,
  ...props
}: { audioURL: string } & Partial<ModalProps>) => {
  return (
    <FullScreenOverlay
      closeButtonClassName={tw(
        "!text-icon-action-inverse drop-shadow opacity-75 hover:opacity-100",
        "absolute top-8 right-8 w-40 h-44 z-1",
        "md:top-16 md:size-24 md:right-16"
      )}
      {...props}
    >
      <audio
        className="absolute max-h-full max-w-full left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2"
        onCanPlay={e => e.target instanceof HTMLAudioElement && e.target.play()}
        src={audioURL}
        style={{ maxWidth: "300px" }}
        controls
      >
        Your browser does not support the
        <code>audio</code> element.
      </audio>
    </FullScreenOverlay>
  );
};

export default AudioModal;
