import {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

import axios from "axios";
import { parse as parseExif } from "exifr";
import { uniqBy } from "lodash-es";

import { FileOrImageUpload, GlueFile } from "@utility-types";
import { glueFileToFileUpload } from "components/MessageEditor/stream-helpers";
import {
  useCreateFileMutation,
  useUploadTicketMutation,
} from "generated/graphql";
import { useSnackbar } from "providers/SnackbarProvider";
import { computeMD5Sum } from "utils/md5sum";

type State = Map<string, FileOrImageUpload>;

interface Props {
  onChange: (state: State) => void;
  orderedUploads: MutableRefObject<Map<string, FileOrImageUpload>>;
}

type FileMetadata = {
  height: number;
  width: number;
};

type UploadProgress = {
  glueId?: string;
  uploadID: string;
  progress: number;
};

const loadImageMetadata: (src: File) => Promise<FileMetadata | undefined> =
  async src =>
    parseExif(src, { skip: [], translateValues: false, xmp: true })
      .then(async tags => {
        let height: number | undefined;
        let width: number | undefined;
        let imgWidth: number | undefined;
        let imgHeight: number | undefined;

        // Hipstamatic doesn't have the correct tags; use the other promise;
        // last checked against Hipstamatic v10.3
        const skipTags = tags?.Make === "Hipstamatic";

        const promises = [
          // use the first available image height and width tag from metadata
          new Promise(resolve => {
            if (skipTags) return;

            const imgHeight =
              tags?.ImageHeight ??
              tags?.ExifImageHeight ??
              tags?.PixelYDimension;
            const imgWidth =
              tags?.ImageWidth ?? tags?.ExifImageWidth ?? tags?.PixelXDimension;

            if (imgHeight && imgWidth) {
              resolve(
                (() => {
                  height = imgHeight;
                  width = imgWidth;
                })()
              );
            }
          }),
          // use the actual image height and width if the metadata is missing
          new Promise(resolve => {
            if (tags && !skipTags) return;

            const img = new Image();
            img.crossOrigin = "Anonymous";
            img.src = URL.createObjectURL(src);
            img.onload = () => {
              resolve(
                (() => {
                  imgWidth = img.width;
                  imgHeight = img.height;
                })()
              );
            };
          }),
        ];

        return Promise.race(promises).then(() => {
          const h = height ?? imgHeight;
          const w = width ?? imgWidth;

          // Orientation values 1-4 are for landscape orientation, 5-8 for portrait orientation;
          // if undefined, we default to landscape orientation (e.g. height and width values should NOT be swapped)
          const isRotated =
            Number.parseInt(tags?.Orientation?.toString() ?? "1") >= 5;
          const actualHeight = isRotated ? w : h;
          const actualWidth = isRotated ? h : w;

          if (actualHeight && actualWidth) {
            const xRes = Number.parseInt(tags?.XResolution?.toString() ?? "72");

            // 72 pixels per inch is the standard for online use;
            // we can make the imperfect assumption that the image is a screenshot if the resolution is divisible by 72
            const isScreenRes = xRes % 72 === 0;

            let multiplier =
              isScreenRes || tags?.UserComment === "Screenshot" // Apple includes a UserComment tag for screenshots on iOS and macOS
                ? xRes / 72
                : 1;

            if (multiplier < 1) multiplier = 1;

            return {
              height: Math.floor(actualHeight / multiplier),
              width: Math.floor(actualWidth / multiplier),
            };
          }
        });
      })
      .catch(e => {
        console.error("Error loading image metadata", e);

        // in cases where exifr cannot recognize the file type (e.g. webp), try to get dimensions from the file
        return new Promise<FileMetadata>(resolve => {
          const img = new Image();
          img.crossOrigin = "Anonymous";
          img.src = URL.createObjectURL(src);
          img.onload = () => {
            resolve({
              height: img.height || 0,
              width: img.width || 0,
            });
          };
        });
      });

const useFileUploader = ({ onChange, orderedUploads }: Props) => {
  const [uploadProgress, setUploadProgress] = useState<UploadProgress[]>();
  const [createUploadTicket] = useUploadTicketMutation();
  const [createFile] = useCreateFileMutation();
  const { openSnackbar } = useSnackbar();
  const processedUploads = useRef<{ glueId: string; tempId: string }[]>([]);

  const uploadFile = useCallback(
    async (upload: FileOrImageUpload): Promise<GlueFile | undefined> => {
      const {
        contentType,
        id: uploadID,
        uploadInfo: { file },
      } = upload;

      if (!(file instanceof File)) {
        return undefined;
      }

      let fileToUpload = file;

      // If the file is an .avif image, convert it to png
      if (file.name.endsWith(".avif")) {
        try {
          fileToUpload = await convertAvifToPng(file);
        } catch (error) {
          openSnackbar(
            "error",
            "Sorry, we encountered an issue processing your file."
          );
          throw error;
        }
      }

      const md5Sum = await computeMD5Sum(fileToUpload);
      if (!md5Sum) {
        openSnackbar(
          "error",
          "Sorry, we encountered an issue processing your file."
        );
        throw new Error("unable to calculate md5");
      }

      const objectURL = contentType.startsWith("image")
        ? URL.createObjectURL(fileToUpload)
        : undefined;

      if (objectURL) {
        upload.uploadInfo.previewUri = objectURL;
      }

      // uploadTicketData data will change if the file has been already uploaded
      const { data: uploadTicketData } = await createUploadTicket({
        variables: {
          input: {
            contentLength: fileToUpload.size.toString(),
            contentMD5: md5Sum,
            contentType: await getContentType(fileToUpload, contentType),
            metadata: contentType.startsWith("image")
              ? await loadImageMetadata(fileToUpload)
              : undefined,
            name: fileToUpload.name,
          },
        },
      });

      if (!uploadTicketData) throw new Error("failed creating upload ticket");

      if (uploadTicketData.uploadTicket.__typename === "File") {
        const contentType = uploadTicketData.uploadTicket.contentType;
        setUploadProgress(v =>
          uniqBy([{ uploadID, progress: 100 }, ...(v || [])], "uploadID")
        );
        return {
          ...uploadTicketData.uploadTicket,
          contentType: await getContentType(fileToUpload, contentType),
        };
      }

      const {
        uploadTicket: { formData, headers, id: uploadTicketID, url },
      } = uploadTicketData;

      // TODO: Test Axios request and createFile mutation

      const cancelTokenSource = axios.CancelToken.source();
      upload.uploadInfo.axiosCancelToken = cancelTokenSource;

      const uploadOptions = {
        cancelToken: cancelTokenSource.token,
        headers: Object.fromEntries(
          headers.map(({ name, value }) => [name, value])
        ),
        onUploadProgress: (e: ProgressEvent) => {
          const progress = Math.round((100 * e.loaded) / e.total);
          setUploadProgress(v =>
            uniqBy([{ uploadID, progress }, ...(v || [])], "uploadID")
          );
        },
      };

      await (formData
        ? axios.post(
            url,
            formData.reduce((data, { name, value }) => {
              data.set(name, value === "@file" ? fileToUpload : value);
              return data;
            }, new FormData()),
            uploadOptions
          )
        : axios.put(url, fileToUpload, uploadOptions)
      ).catch(error => {
        if (!orderedUploads.current.get(uploadID)) {
          throw new Error("upload was canceled");
        }
        throw error;
      });

      const { data: fileData } = await createFile({
        variables: { uploadTicketID: uploadTicketID },
      });

      if (!fileData) return undefined;

      return fileData.createFile;
    },
    [createUploadTicket, createFile, openSnackbar, orderedUploads]
  );

  const cancelUploadsRef = useRef(() =>
    [...orderedUploads.current.values()].forEach(upload =>
      upload.uploadInfo.axiosCancelToken?.cancel()
    )
  );

  useEffect(() => {
    const queued = [...orderedUploads.current.values()].filter(
      ({ uploadInfo }) => uploadInfo.state === "uploading" && uploadInfo.queued
    );
    queued.forEach(upload => {
      uploadFile(upload)
        .then(glueFile => {
          if (!glueFile) return;
          const { id: glueFileId } = glueFile;

          const uploads = [...orderedUploads.current.values()];

          const existing = uploads
            .filter(({ uploadInfo: { state } }) => state === "finished")
            .find(
              ({ id: fileId, uploadInfo: { id: uploadId } }) =>
                glueFileId === fileId || glueFileId === uploadId
            );

          const fileUpload = existing || {
            ...glueFileToFileUpload(glueFile),
            uploadInfo: { ...upload.uploadInfo, state: "finished" },
          };

          if (processedUploads) {
            setUploadProgress(v => {
              const file = v?.find(u => u.uploadID === upload.uploadInfo.id);
              return file
                ? uniqBy(
                    [{ ...file, glueId: fileUpload.id }, ...(v || [])],
                    "uploadID"
                  )
                : v;
            });
            processedUploads.current.push({
              glueId: fileUpload.id,
              tempId: upload.uploadInfo.id,
            });
          }

          const tempOrderedUploads = Array.from(orderedUploads.current).filter(
            u => u[0] !== existing?.uploadInfo.id
          );
          const existingIndex = uploads.findIndex(u => upload.id === u.id);

          existingIndex >= 0 &&
            tempOrderedUploads.splice(existingIndex, 1, [
              glueFileId,
              fileUpload,
            ]);

          orderedUploads.current = new Map(tempOrderedUploads);

          onChange(orderedUploads.current);
        })
        .catch((error: Error) => {
          if (error.message !== "upload was canceled") {
            console.warn("Error: [uploadFile] -", error);
            upload.uploadInfo.state = "failed";

            onChange(orderedUploads.current);
          }
        });

      upload.uploadInfo.queued = false;

      orderedUploads.current.set(upload.id, upload);

      onChange(orderedUploads.current);
    });
  }, [onChange, uploadFile, orderedUploads]);

  useEffect(() => {
    const handler = cancelUploadsRef.current;

    window.addEventListener("offline", handler);

    return () => window.removeEventListener("offline", handler);
  }, [orderedUploads]);

  useEffect(() => () => cancelUploadsRef.current(), []);

  return { uploadProgress, processedUploads, cancelUploadsRef };
};

async function getContentType(file: File, contentType: string) {
  let type = contentType;

  if (contentType.includes("video/")) {
    type = (await new Promise<boolean>(res => {
      const video = document.createElement("video");
      const url = window.URL.createObjectURL(file);

      video.preload = "metadata";

      video.onloadedmetadata = () => {
        res(!!(video.videoHeight && video.videoWidth));

        window.URL.revokeObjectURL(url);

        video.src = "null";
      };

      video.src = url;
    }))
      ? contentType
      : contentType.replace("video", "audio");
  }

  return type;
}

async function convertAvifToPng(file: File): Promise<File> {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.src = URL.createObjectURL(file);
    image.onload = () => {
      // Create a regular HTML5 canvas element.
      const canvas = document.createElement("canvas");
      canvas.width = image.width;
      canvas.height = image.height;

      // Get the context from the canvas.
      const ctx = canvas.getContext("2d");
      if (!ctx) {
        reject(new Error("Unable to get canvas context"));
        return;
      }

      // Draw the image onto the canvas.
      ctx.drawImage(image, 0, 0);

      // Convert the canvas content to a blob.
      canvas.toBlob(blob => {
        if (!blob) {
          reject(new Error("Unable to convert to blob"));
          return;
        }

        const pngFile = new File([blob], file.name.replace(/\.avif$/, ".png"), {
          type: "image/png",
        });

        resolve(pngFile);
      }, "image/png");

      // Remove the canvas from the DOM.
      canvas.remove();
    };

    image.onerror = () => {
      reject(new Error("Failed to load image"));
    };
  });
}

export default useFileUploader;
