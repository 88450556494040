import { isEqual } from "lodash-es";

import { Draft, Message, Recipient } from "@utility-types";
import { EditorMessage } from "components/MessageEditor/types";
import { Addable, AppUnfurlSetup, SaveDraftInput } from "generated/graphql";

export type DraftForm = Pick<
  SaveDraftInput,
  "subject" | "recipientsAddable"
> & {
  message: EditorMessage;
  recipients: Recipient[];
  replyToMessage: Pick<Message, "id" | "threadID"> | Message | null;
  appUnfurlSetups: AppUnfurlSetup[];
};

export const draftToForm = (draft: Draft): DraftForm => ({
  subject: draft.subject,
  message: {
    text: draft.message.text,
    attachments: draft.message.attachments,
  },
  recipients: draft.recipients,
  recipientsAddable: draft.recipientsAddable,
  replyToMessage: draft.replyToMessage,
  appUnfurlSetups: [],
});

export const formToInput = (form: DraftForm): SaveDraftInput => ({
  subject: form.subject,
  message: {
    text: form.message.text,
    attachments: form.message.attachments.map(({ id }) => id),
  },
  recipients: form.recipients.map(({ id }) => id),
  recipientsAddable: form.recipientsAddable,
  replyToMessageID: form.replyToMessage?.id,
});

type PendingAction = "load" | "save" | "send" | "delete" | false;

export type State = {
  draftID: string | undefined;
  draftForm: DraftForm;
  dirty: boolean;
  error: string | undefined;
  pending: PendingAction;
};

export const InitialState: State = {
  draftID: undefined,
  draftForm: {
    subject: "",
    recipients: [],
    message: {
      attachments: [],
      text: "",
    },
    replyToMessage: null,
    recipientsAddable: Addable.Anyone,
    appUnfurlSetups: [],
  },
  dirty: false,
  error: undefined,
  pending: false,
};

export type Action =
  | { type: "reset"; pending?: "load" }
  | { type: "loaded"; draft: Draft }
  | { type: "change"; draftForm: Partial<DraftForm> }
  | { type: "save" }
  | { type: "saved"; draft: Draft }
  | { type: "send" }
  | { type: "delete" }
  | { type: "error" };

export const DraftReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "reset": {
      return { ...InitialState, pending: action.pending || false };
    }
    case "loaded": {
      let { pending } = state;
      pending = pending === "load" ? false : pending;

      const { draft } = action;
      return {
        ...InitialState,
        draftID: draft.id,
        draftForm: draftToForm(draft),
        dirty: false,
        pending,
      };
    }
    case "change": {
      if (state.pending === "load") return state;

      const draftForm = {
        ...state.draftForm,
        ...action.draftForm,
      };

      if (isEqual(state.draftForm, draftForm)) return state;

      return {
        ...state,
        draftForm,
        dirty: true,
      };
    }
    case "save": {
      return { ...state, pending: "save" };
    }
    case "saved": {
      let { pending } = state;
      pending = pending === "save" ? false : pending;

      return {
        ...state,
        draftID: action.draft.id,
        dirty: false,
        pending,
      };
    }
    case "send": {
      return { ...state, pending: "send" };
    }
    case "delete": {
      return { ...state, pending: "delete" };
    }
    case "error": {
      return { ...state, pending: false };
    }
  }
};
