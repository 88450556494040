import { useRef } from "react";
import { useHistory } from "react-router-dom";

import { nodeAs } from "@utility-types";
import Feed from "components/Feed/Feed";
import { Skeleton } from "components/Skeleton";
import Icon from "components/design-system/icons/Icon";
import FacePile from "components/design-system/ui/FacePile";
import NewThreadFeedWidget from "components/design-system/ui/NewThreadFeedWidget";
import GroupProfileModal, {
  GroupProfileTab,
} from "components/group/GroupModal/GroupProfileModal";
import { NotInGroup } from "components/group/NotInGroup";
import { usePartitionState } from "components/routing/RoutingPartition";
import {
  GroupTabs,
  drawerParams,
  routeParams as getRouteParams,
  locationFromRoute,
  tabPath,
} from "components/routing/utils";
import ThreadList from "components/threads-list/ThreadList";
import ThreadView from "components/threads/ThreadView";
import {
  WorkspaceModal,
  WorkspaceModalTabs,
} from "components/workspace/WorkspaceModal";
import { ThreadsMailbox } from "generated/graphql";
import { MemberRole } from "generated/graphql";
import { useGetRecipientExternalData } from "hooks/useGetRecipientExternalData";
import useGroupEdge from "hooks/useGroupEdge";
import useMemberEdge from "hooks/useMemberEdge";
import useModalStore from "store/useModalStore";
import tw from "utils/tw";

import EmptyView from "components/views/EmptyView";
import useElementBreakpoint from "hooks/useElementBreakpoint";
import GroupHeader from "./GroupHeader";
import RecentlyShared from "./Shared/RecentlyShared";
import Shared from "./Shared/Shared";
import { WORKSPACE_GENERAL_DESCRIPTION } from "./consts";

type Props = { recipientID: string; defaultTab?: GroupTabs };

const Group = ({ recipientID, defaultTab = GroupTabs.Chat }: Props) => {
  const history = useHistory();
  const { route } = usePartitionState(({ route }) => ({ route }));
  const routeParams = getRouteParams(locationFromRoute(route));
  const mainEl = useRef<HTMLDivElement>(null);
  const scrollEl = useRef<HTMLDivElement>(null);
  const isWide = useElementBreakpoint(mainEl.current, "wide");

  const { openModal } = useModalStore(({ openModal }) => ({
    openModal,
  }));
  const groupID = recipientID === "groups" ? undefined : recipientID;
  const { edge, loading } = useGroupEdge(groupID);
  const { memberEdge, previewEdge } = useMemberEdge(edge);
  const workspaceEdge = nodeAs(edge, ["WorkspaceEdge", "WorkspacePreviewEdge"]);
  const groupEdge = nodeAs(edge, ["GroupEdge", "GroupPreviewEdge"]);

  const { tooltip } = useGetRecipientExternalData(groupEdge?.node);

  const { threadID } = drawerParams(routeParams.d ?? "");

  const showSidebar = !threadID && !!isWide;

  const currentTab = (() => {
    switch (routeParams.t) {
      case "Threads":
        return GroupTabs.Threads;
      case "Feed":
        return GroupTabs.Feed;
      case "Shared":
        return GroupTabs.Shared;
      case "Chat":
        if (groupID) {
          return GroupTabs.Chat;
        }
    }
  })();

  const currentView = previewEdge ? "Preview" : currentTab;

  const handleOpenGroupModal = ({
    groupId,
    defaultTab = GroupProfileTab.Members,
    focusOnDescriptionField,
  }: {
    groupId: string;
    defaultTab?: GroupProfileTab;
    focusOnDescriptionField?: boolean;
  }) => {
    openModal(
      <GroupProfileModal
        defaultTab={defaultTab}
        groupID={groupId}
        isAdmin={memberEdge?.memberRole === MemberRole.Admin}
        focusOnDescriptionField={focusOnDescriptionField}
      />
    );
  };

  const handleOpenWorkspaceModal = ({
    workspaceID,
    defaultTab = WorkspaceModalTabs.Members,
  }: { workspaceID: string; defaultTab?: WorkspaceModalTabs }) => {
    openModal(
      <WorkspaceModal defaultTab={defaultTab} workspaceID={workspaceID} />
    );
  };

  const handleOpenModal = () => {
    if (workspaceEdge) {
      handleOpenWorkspaceModal({
        workspaceID: workspaceEdge.node.id,
      });
    } else if (groupEdge) {
      handleOpenGroupModal({
        groupId: groupEdge.node.id,
      });
    }
  };

  const handleClickDescription = () => {
    if (!groupEdge) return;
    handleOpenGroupModal({
      groupId: groupEdge.node.id,
      defaultTab: GroupProfileTab.Settings,
      focusOnDescriptionField: true,
    });
  };

  // Redirect to first tab if no tab, and each time super-tab is loaded
  const hasMounted = useRef(false);
  if (!currentTab || (currentTab !== defaultTab && !hasMounted.current)) {
    history.replace(tabPath(defaultTab));
    return null;
  }
  hasMounted.current = true;

  if ((!edge && !loading) || !groupID) {
    return <EmptyView />;
  }

  return (
    <>
      <header className="sticky shrink-0 top-0 bg-background-body border-b-1 border-border-strong p-16 pb-0">
        <GroupHeader
          edge={edge}
          currentTab={currentTab}
          onOpenModal={handleOpenModal}
          onOpenGroupModal={handleOpenGroupModal}
        />
      </header>

      <main
        ref={mainEl}
        className={tw(
          "flex grow min-w-0 min-h-0 gap-16 bg-background-app-secondary",
          { "pr-16": showSidebar && currentView === GroupTabs.Chat },
          { "px-16": isWide && currentView !== GroupTabs.Chat }
        )}
      >
        {currentView === "Preview" && <NotInGroup groupID={groupID} />}

        {currentView === GroupTabs.Chat && (
          <div className="grow min-w-0 h-full overflow-y-auto bg-background-body border-border-container border-r-1">
            <ThreadView
              headerType="none"
              mailbox={ThreadsMailbox.All}
              messageID={routeParams.messageID}
              recipientID={groupID}
              stackPriority={0}
              threadID={
                routeParams.threadID ?? memberEdge?.persistentChatEdge.node.id
              }
            />
          </div>
        )}

        {currentView === GroupTabs.Threads && (
          <div
            className={tw(
              "flex flex-col grow min-w-0 max-w-[832px] gap-16 overflow-hidden",
              { "pt-16": isWide }
            )}
          >
            <div
              className={tw(
                "bg-background-body shadow-level1 flex flex-col grow min-h-0 overflow-auto",
                isWide && "md:rounded-t-lg"
              )}
            >
              <ThreadList
                headerType="recent"
                mailbox={ThreadsMailbox.All}
                recipient={memberEdge}
                recipientID={groupID}
                selectedID={threadID}
                compact
                disableBulkSelect
                excludeChats
                skipAutoSelect
              />
            </div>
          </div>
        )}

        {currentView === GroupTabs.Feed && (
          <div
            className="flex flex-col grow min-w-0 max-w-[832px] gap-16 pt-16 overflow-y-auto"
            ref={scrollEl}
          >
            <NewThreadFeedWidget
              className={tw(!isWide && "md:!rounded-none")}
            />

            <Feed
              key={groupID}
              feedID={groupID}
              roundedCard={!!isWide}
              scrollEl={scrollEl.current}
            />
          </div>
        )}

        {currentView === GroupTabs.Shared && (
          <Shared isWide={!!isWide} groupID={groupID} />
        )}

        {showSidebar && (
          <aside className={tw("flex flex-col shrink-0 w-250 gap-16 py-16")}>
            <div className="bg-background-body py-12 px-16 rounded-lg shadow-level1">
              <header className="flex items-center justify-between text-footnote-bold mb-8 text-text-primary select-none">
                Description
                {groupEdge?.__typename === "GroupEdge" && (
                  <button
                    aria-label="Edit group description"
                    onClick={handleClickDescription}
                  >
                    <Icon
                      icon="EditSimple"
                      size={14}
                      className="text-icon-secondary"
                    />
                  </button>
                )}
              </header>
              <div className="text-footnote text-text-subtle">
                {workspaceEdge ? (
                  WORKSPACE_GENERAL_DESCRIPTION
                ) : groupEdge ? (
                  groupEdge.node.description?.trim() ||
                  (groupEdge?.__typename === "GroupEdge" && (
                    <button
                      className="select-none"
                      onClick={handleClickDescription}
                    >
                      Add a description...
                    </button>
                  ))
                ) : (
                  <Skeleton />
                )}
              </div>
            </div>

            <div className="bg-background-body py-12 px-16 rounded-lg shadow-level1">
              <header className="flex items-center justify-between text-footnote-bold mb-8 text-text-primary select-none">
                Members
                {groupEdge?.__typename === "GroupEdge" && (
                  <button aria-label="Edit members" onClick={handleOpenModal}>
                    <Icon
                      icon="Plus"
                      size={16}
                      className="text-icon-secondary"
                    />
                  </button>
                )}
              </header>
              {edge?.node.members.edges && (
                <button onClick={handleOpenModal}>
                  <FacePile
                    size="medium"
                    hasExternal={!!tooltip}
                    tooltip={tooltip || "See all members"}
                    totalCount={edge?.node.members.totalCount}
                    users={edge.node.members.edges.slice(0, 5).map(e => e.node)}
                  />
                </button>
              )}
            </div>

            <RecentlyShared groupID={groupID} />
          </aside>
        )}
      </main>
    </>
  );
};

export default Group;
