import { FullScreenOverlay } from "components/Modals";
import { FileFieldsFragment } from "generated/graphql";
import { ModalProps } from "stream-chat-react";
import tw from "utils/tw";

const VideoModal = ({
  file,
  videoURL,
  ...props
}: { file: FileFieldsFragment; videoURL: string } & Partial<ModalProps>) => (
  <FullScreenOverlay
    closeButtonClassName={tw(
      "!text-icon-action-inverse drop-shadow opacity-75 hover:opacity-100",
      "absolute top-8 right-8 w-40 h-44 z-1",
      "md:top-16 md:size-24 md:right-16"
    )}
    {...props}
  >
    <video
      className="absolute max-h-full max-w-full left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2"
      onCanPlay={e => e.target instanceof HTMLVideoElement && e.target.play()}
      onClick={e => e.stopPropagation()}
      title={file.name}
      controls
    >
      <source
        src={videoURL}
        type={file.contentType.replace("/quicktime", "/mp4") || "video/mp4"}
      />
      <source src={videoURL} />
      Sorry, your browser doesn't support embedded videos.
    </video>
  </FullScreenOverlay>
);

export default VideoModal;
