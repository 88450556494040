import { nodeIs } from "@utility-types/graphql";
import { Button } from "components/design-system/Button";
import { InformationBubble } from "components/design-system/InformationBubble";
import { useFetchJoinApprovalsQuery } from "generated/graphql";
import useOnboardingStore from "store/useOnboardingStore";
import ContentWrapper from "./ContentWrapper";
import Footer from "./Footer";

const NoWorkspaceWall = () => {
  const { currentStep, setState } = useOnboardingStore(
    ({ currentStep, setState }) => ({
      currentStep,
      setState,
    })
  );

  const { data: joinApprovalsData } = useFetchJoinApprovalsQuery({
    fetchPolicy: "cache-and-network",
  });

  const groupOrThreadInvites = joinApprovalsData?.joinApprovals.edges.filter(
    j => nodeIs(j.node.joinable, ["GroupPreview", "ThreadPreview"])
  );

  return (
    <ContentWrapper
      title="You don't have a workspace, yet."
      headline="A workspace is a shared space to work together as a team. You can join a workspace with your work email or with an invitation from a coworker."
    >
      <InformationBubble className="mt-32">
        Looking for a different workspace? Sign in with another work email
        address, request an invitation, or visit Glue on the web.
      </InformationBubble>
      <Footer
        onClickBack={() =>
          setState({
            view: "CompleteYourProfile",
            currentStep: currentStep - 1,
          })
        }
        hideSkip
      >
        {groupOrThreadInvites?.length ? (
          <Button
            onClick={() =>
              setState({
                view: "Review",
                goBackTo: "NoWorkspaceWall",
                currentStep: currentStep + 1,
              })
            }
          >
            Continue without workspace
          </Button>
        ) : null}
      </Footer>
    </ContentWrapper>
  );
};

export default NoWorkspaceWall;
