import {
  AppFieldsFragment,
  CustomEmojiFieldsFragment,
  DraftFieldsFragment,
  ExternalObjectFieldsFragment,
  FileFieldsFragment,
  FileType,
  GroupEdgeFieldsFragment,
  GroupEdgeSimpleFieldsFragment,
  GroupFieldsFragment,
  GroupPreviewFieldsFragment,
  GroupPreviewSimpleFieldsFragment,
  GroupSimpleFieldsFragment,
  JoinApprovalFieldsFragment,
  JoinApprovalSimpleFieldsFragment,
  LinkFieldsFragment,
  LinkedCategory,
  MemberRole,
  MessageEdgeFieldsFragment,
  MessageFieldsFragment,
  MessageMetadataFieldsFragment,
  NotificationEdgeFieldsFragment,
  NotificationFieldsFragment,
  QuotedMessageFieldsFragment,
  SlackImportStatus,
  SubscriptionPlanFieldsFragment,
  ThreadEdgeFieldsFragment,
  ThreadFieldsFragment,
  ThreadInFeedFieldsFragment,
  ThreadPreviewFieldsFragment,
  ThreadSimpleFieldsFragment,
  ThreadsMailbox,
  UnreadCountFieldsFragment,
  UserEdgeFieldsFragment,
  UserFieldsFragment,
  WebhookFieldsFragment,
  WorkspaceAppEdgeFieldsFragment,
  WorkspaceEdgeFieldsFragment,
  WorkspaceEdgeSimpleFieldsFragment,
  WorkspaceFieldsFragment,
  WorkspacePreviewFieldsFragment,
  WorkspacePreviewSimpleFieldsFragment,
  WorkspaceSimpleFieldsFragment,
} from "generated/graphql";

export type Address = { __typename: "Address" } & {
  address: string;
  id: string;
};

// App

export type App = AppFieldsFragment;
export type Webhook = WebhookFieldsFragment;
export type WorkspaceAppEdge = WorkspaceAppEdgeFieldsFragment;

export type CustomEmoji = CustomEmojiFieldsFragment;

// Draft

export type Draft = DraftFieldsFragment;

export type DraftEdge = {
  __typename: "DraftEdge";
  node: {
    __typename: "Draft";
  } & DraftFieldsFragment;
};
// User

export type User = UserFieldsFragment;

export type UserEdge = UserEdgeFieldsFragment & { node: User };

// Group

export type Group = GroupFieldsFragment;

export type GroupSimple = GroupSimpleFieldsFragment;

export type GroupEdge = GroupEdgeFieldsFragment & { node: Group };

export type GroupEdgeSimple = GroupEdgeSimpleFieldsFragment & {
  node: GroupSimple;
};

export type GroupPreview = GroupPreviewFieldsFragment;

export type GroupPreviewSimple = GroupPreviewSimpleFieldsFragment;

export type GroupPreviewEdge = { __typename: "GroupPreviewEdge" } & {
  node: GroupPreview;
};

export type GroupPreviewEdgeSimple = { __typename: "GroupPreviewEdge" } & {
  node: GroupPreviewSimple;
};

export type GroupOrWorkspaceAndPreview =
  | Group
  | GroupPreview
  | Workspace
  | WorkspacePreview;

export type GroupOrPreview = Group | GroupPreview;

export type GroupOrPreviewEdgeSimple = { __typename: "GroupOrPreviewEdge" } & {
  id: string;
  node: GroupPreviewSimple | GroupSimple;
};

// Attachment

export type Attachment =
  | ExternalObject
  | GlueFile
  | ThreadPreview
  | UnknownNode;

export type GlueFile = FileFieldsFragment;

export { FileType };

export type ExternalObject = ExternalObjectFieldsFragment;

export const isGlueFile = (attachment: {
  __typename?: Attachment["__typename"];
}): attachment is GlueFile => nodeIs(attachment, ["File"]);

export const isExternalObject = (attachment: {
  __typename?: Attachment["__typename"];
}): attachment is ExternalObject => nodeIs(attachment, ["ExternalObject"]);

export const isThreadPreview = (attachment: {
  __typename?: Attachment["__typename"];
}): attachment is ThreadPreview => nodeIs(attachment, ["ThreadPreview"]);

export type GlueLink = LinkFieldsFragment;

// JoinApproval

export type JoinApproval = JoinApprovalFieldsFragment;

export type JoinApprovalSimple = JoinApprovalSimpleFieldsFragment;

export type JoinApprovalEdge = { __typename: "JoinApprovalEdge" } & {
  node: JoinApproval;
};

export type JoinApprovalSimpleEdge = { __typename: "JoinApprovalEdge" } & {
  node: JoinApprovalSimple;
};

export type JoinApprovalSimpleConnection = {
  edges: JoinApprovalSimpleEdge[];
  totalCount: number;
};

export type EphemeralMessage = Pick<
  MessageFieldsFragment,
  "id" | "text" | "threadID" | "createdAt" | "updatedAt" | "user"
> & {
  feedbackType?: string;
  unfurlMessageID?: string;
};

export type QuotedMessage = QuotedMessageFieldsFragment;

export type Message = MessageFieldsFragment;

export type MessageEdge = MessageEdgeFieldsFragment & {
  node: MessageFieldsFragment;
};

export type MessageConnection = {
  edges: MessageEdge[];
  replyCount: number;
  totalCount: number;
  unreadCounts: UnreadCounts;
};

export type MessageMetadata = MessageMetadataFieldsFragment;

export type Notification = NotificationFieldsFragment;

export type NotificationEdge = NotificationEdgeFieldsFragment & {
  node: Notification;
};

// Thread

export type Thread = ThreadFieldsFragment;

export type ThreadSimple = ThreadSimpleFieldsFragment;

export type ThreadEdge = ThreadEdgeFieldsFragment & { node: Thread };

export type ThreadEdgeFeed = ThreadEdgeFieldsFragment & {
  node: ThreadInFeedFieldsFragment;
};

export type ThreadEdgeSimple = ThreadEdgeFieldsFragment & {
  node: ThreadSimple;
};

export type ThreadPreview = ThreadPreviewFieldsFragment;

export type ThreadPreviewEdge = {
  __typename: "ThreadPreviewEdge";
} & { id?: string; node: ThreadPreview };

export type UnreadCounts = UnreadCountFieldsFragment;

export type UnreadCountsTypes = keyof Omit<
  UnreadCountFieldsFragment,
  "__typename"
>;

// Workspace

export type Workspace = WorkspaceFieldsFragment;

export type WorkspaceSimple = WorkspaceSimpleFieldsFragment;

export type WorkspaceEdge = WorkspaceEdgeFieldsFragment & { node: Workspace };

export type WorkspaceEdgeSimple = WorkspaceEdgeSimpleFieldsFragment & {
  node: WorkspaceSimple;
};

export type WorkspacePreview = WorkspacePreviewFieldsFragment;

export type WorkspacePreviewSimple = WorkspacePreviewSimpleFieldsFragment;

export type WorkspacePreviewEdge = { __typename: "WorkspacePreviewEdge" } & {
  node: WorkspacePreview;
};

export type SubscriptionPlan = SubscriptionPlanFieldsFragment;

export type MemberEdge = {
  memberRole?: MemberRole;
  node: User;
};

export type MemberConnection = {
  edges: MemberEdge[];
  totalCount: number;
};

export type RecipientType =
  | "Address"
  | "User"
  | "Group"
  | "GroupPreview"
  | "Workspace"
  | "WorkspacePreview";

export type Recipient = {
  __typename: RecipientType;
} & Pick<User, "id" | "name"> & // Address, User, Group, Workspace
  Partial<Pick<Workspace, "domains">> &
  Partial<Pick<User, "addressDomains" | "avatarURL" | "bio" | "workspaceIDs">> &
  Partial<Pick<Group, "commonWorkspaceIDs" | "workspaceID">>;

export type RecipientWithPreviews = Recipient &
  Partial<Pick<GroupPreview, "members">> &
  Partial<Pick<WorkspacePreview, "admin">>;

export type RecipientEdge = {
  __typename: `${RecipientType}Edge`;
  id?: string;
  node: Recipient;
};

export { ThreadsMailbox as Mailbox };

export { SlackImportStatus };

export { LinkedCategory as LinksCategory };

export type UnknownNode = { __typename: "__unknown"; id: string };

// Helpers

export const nodeIs = <
  T extends { __typename?: string },
  U extends T["__typename"],
  V extends Extract<T, { __typename: U }>,
>(
  node: T | null | undefined,
  typenames: U[]
): node is V => {
  if (!node?.__typename) return false;

  for (const type of typenames) {
    if (node.__typename === type) return true;
  }

  return false;
};

export const nodeAs = <
  T extends { __typename: string },
  U extends T["__typename"],
>(
  node: T | null | undefined,
  typenames: U[]
) => {
  if (nodeIs(node, typenames)) return node;
};
