const clickTemporalLink = (href: string, download?: string) => {
  const anchor = document.createElement("a");
  anchor.href = href;
  anchor.style.display = "none";
  anchor.target = "_blank";
  if (download) anchor.download = download;
  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);
};

export default clickTemporalLink;
