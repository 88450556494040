import { DetailedHTMLProps, LiHTMLAttributes, useMemo, useRef } from "react";
import { useHistory } from "react-router-dom";

import { LinksCategory } from "@utility-types";
import FilePreview, {
  FilePreviewRef,
} from "components/FilePreview/FilePreview";
import { PhotoSwipeGallery, PhotoSwipeItem } from "components/PhotoSwipe";
import { Skeleton } from "components/Skeleton";
import { cloneElementForSkeletons } from "components/Skeleton/Skeleton";
import Avatar from "components/design-system/Avatar/Avatar";
import { Button } from "components/design-system/Button";
import { Tooltip } from "components/design-system/FloatingUi";
import { Icon } from "components/design-system/icons";
import { FileIcon } from "components/helper";
import { GroupTabs, tabPath } from "components/routing/utils";
import { FileFieldsFragment, FileType, useLinksQuery } from "generated/graphql";
import { Blurhash } from "react-blurhash";
import useModalStore from "store/useModalStore";
import fixDevApiUrl from "utils/fixDevApiUrl";
import glueImageURL from "utils/glueImageURL";
import AudioModal from "./Media/AudioModal";
import VideoModal from "./Media/VideoModal";
import { parseImage } from "./utils/parseImage";

type ListItemProps = {
  avatarURL?: string;
  file?: FileFieldsFragment;
  id: string;
  mimeType?: string;
  name: string;
  onClick?: () => void;
  tooltip: string;
  url?: string;
};

const EmptyState = () => {
  return (
    <div className="pl-12 pr-16 pb-8">
      <span className="text-subhead italic text-text-secondary ml-4">
        No recently shared items.
      </span>
    </div>
  );
};

const recentlySharedLimit = 5;
const RecentlyShared = ({ groupID }: { groupID: string }) => {
  const filePreviewRef = useRef<FilePreviewRef>(null);
  const history = useHistory();
  const { openModal } = useModalStore(({ openModal }) => ({
    openModal,
  }));
  const { data, loading } = useLinksQuery({
    variables: {
      filter: {
        recipientIDs: [groupID],
        linkedCategories: [
          LinksCategory.File,
          LinksCategory.Media,
          LinksCategory.Link,
        ],
      },
      last: recentlySharedLimit - 1,
    },
    fetchPolicy: "cache-and-network",
  });

  const edges = data?.links.edges.map(e => e.node.linked).reverse();

  const isFileWithPreview = (fileType: FileType) =>
    fileType === FileType.Image || fileType === FileType.Video;

  const links = useMemo(() => {
    const items: ListItemProps[] = [];
    edges?.forEach(e => {
      switch (e.__typename) {
        case "ExternalObject":
          items.push({
            avatarURL: e.icon?.url ?? "",
            id: e.id,
            name: e.title,
            onClick: () => window.open(e.url, "_blank"),
            tooltip: e.url,
          });
          break;
        case "File":
          items.push({
            avatarURL: isFileWithPreview(e.fileType) ? e.url : undefined,
            file: { ...e },
            id: e.id,
            mimeType: !isFileWithPreview(e.fileType)
              ? e.contentType
              : undefined,
            name: e.name,
            url: e.url,
            tooltip: e.name,
            onClick: e.previewable
              ? () =>
                  filePreviewRef.current?.openPreview({
                    id: e.id,
                    title: e.name,
                    url: e.url,
                  })
              : undefined,
          });
          break;
      }
    });
    return items;
  }, [edges]);

  const ListItem = ({
    avatarURL,
    id,
    mimeType,
    name,
    onClick,
    tooltip,
    url,
  }: ListItemProps) => {
    return (
      <Tooltip content={tooltip} tooltipStyle="inverted">
        <a
          key={id}
          className="h-32 flex items-center pl-4 rounded-md hover:bg-background-body-hover cursor-pointer"
          href={url}
          onClick={
            onClick
              ? e => {
                  e.preventDefault();
                  onClick();
                }
              : undefined
          }
          download
        >
          {avatarURL && (
            <Avatar
              className="mx-2"
              size="tiny"
              background="transparent"
              avatarURL={avatarURL}
            />
          )}
          {mimeType && (
            <div className="flex items-center justify-center min-w-16 mx-2">
              <FileIcon mimeType={mimeType} iconSize="tiny" />
            </div>
          )}
          <span className="ml-4 text-subhead truncate">{name}</span>
        </a>
      </Tooltip>
    );
  };

  const ListItemWithTooltip = ({
    tooltip,
    ...props
  }: DetailedHTMLProps<LiHTMLAttributes<HTMLLIElement>, HTMLLIElement> & {
    tooltip: string;
  }) => (
    <Tooltip content={tooltip} tooltipStyle="inverted">
      <li {...props} />
    </Tooltip>
  );

  const MediaItem = ({ file, mimeType, name, tooltip }: ListItemProps) => {
    if (!file) return null;

    if (file.fileType === "audio") {
      const audioURL = fixDevApiUrl(file.url);

      return (
        <ListItemWithTooltip
          key={file.id}
          className="h-32 flex items-center pl-4 cursor-pointer mx-2 rounded-md hover:bg-background-body-hover"
          onClick={() => {
            openModal(<AudioModal audioURL={audioURL} />);
          }}
          tooltip={tooltip}
        >
          <Icon className="text-icon-secondary" icon="Volume2" size={16} />
          <span className="ml-4 text-subhead truncate">{name}</span>
        </ListItemWithTooltip>
      );
    }

    if (file.fileType === "image") {
      const {
        blurHash,
        height,
        imageAlt,
        imageSrc,
        imageResize,
        thumbnailHeight,
        thumbnailResize,
        thumbnailWidth,
        width,
      } = parseImage(file);

      return (
        <PhotoSwipeItem
          key={file.id}
          fullSizeURL={glueImageURL(imageSrc, imageResize, 2)}
          height={height}
          id={file.id}
          originalURL={imageSrc}
          thumbnail={glueImageURL(imageSrc, thumbnailResize, 2)}
          title={imageAlt}
          width={width}
        >
          {({ bindLongPress, open, ref }) => (
            <ListItemWithTooltip
              key={file.id}
              className="h-32 flex items-center pl-4 cursor-pointer rounded-md hover:bg-background-body-hover"
              {...bindLongPress}
              onClick={open}
              tooltip={tooltip}
            >
              <div className="relative shrink-0 w-16 h-16 mx-2">
                {
                  // API sent invalidate data in the past,
                  // so validate proper length to avoid crash
                  blurHash?.length === 54 ? (
                    <Blurhash
                      className="absolute inset-0"
                      hash={blurHash}
                      height="100%"
                      punch={1}
                      resolutionX={32}
                      resolutionY={32}
                      width="100%"
                    />
                  ) : (
                    <div className="!absolute inset-0 bg-background-secondary" />
                  )
                }
                {imageSrc ? (
                  <picture className="absolute inset-0">
                    <source
                      srcSet={`${glueImageURL(imageSrc, thumbnailResize)} 1x, ${glueImageURL(
                        imageSrc,
                        thumbnailResize,
                        2
                      )} 2x`}
                    />
                    <img
                      ref={ref}
                      alt={imageAlt || ""}
                      className="object-cover opacity-0 transition-opacity duration-300 w-full h-full"
                      height={thumbnailHeight}
                      loading="lazy"
                      onLoad={({ currentTarget }) =>
                        currentTarget.classList.remove("opacity-0")
                      }
                      src={glueImageURL(imageSrc, thumbnailResize, 0)}
                      width={thumbnailWidth}
                    />
                  </picture>
                ) : null}
              </div>
              {mimeType && (
                <div className="flex items-center justify-center min-w-24 mx-2">
                  <FileIcon mimeType={mimeType} iconSize="tiny" />
                </div>
              )}
              <span className="ml-4 text-subhead truncate">{name}</span>
            </ListItemWithTooltip>
          )}
        </PhotoSwipeItem>
      );
    }

    if (file.fileType === "video") {
      const videoURL = fixDevApiUrl(file.url);

      return (
        <ListItemWithTooltip
          key={file.id}
          className="h-32 flex items-center pl-4 cursor-pointer rounded-md hover:bg-background-body-hover"
          onClick={() => {
            openModal(<VideoModal file={file} videoURL={videoURL} />);
          }}
          tooltip={tooltip}
        >
          <div className="w-24 mx-2">
            <Icon className="text-icon-secondary" icon="PlayCircle" size={16} />
          </div>
          <span className="ml-4 text-subhead truncate">{name}</span>
        </ListItemWithTooltip>
      );
    }

    return null;
  };

  const Skeletons = () => (
    <div className="h-32 flex items-center pl-4 rounded-md">
      <Skeleton className="mx-2" height="16px" width="16px" />
      <Skeleton className="ml-4" height="20px" width="130px" />
    </div>
  );

  const isMedia = (file?: FileFieldsFragment) =>
    file?.__typename === "File" &&
    ["audio", "image", "video"].includes(file.fileType ?? "");

  return (
    <div className="bg-background-body rounded-lg shadow-level1">
      <div className="flex items-center justify-between h-38 px-16 pt-12 pb-8 text-footnote-bold">
        Recently shared
        <Button
          buttonStyle="icon-secondary"
          buttonType="icon"
          icon="ArrowRight"
          iconSize={16}
          onClick={() => {
            history.push(tabPath(GroupTabs.Shared));
          }}
        />
      </div>
      {(data?.links.totalCount ?? 0) > 0 || loading ? (
        <>
          <FilePreview
            ref={filePreviewRef}
            files={links
              .filter(f => !isMedia(f.file) && f.file?.previewable)
              .map(f => ({
                id: f.id,
                title: f.name,
                url: f.file?.url ?? "",
              }))}
          />
          <PhotoSwipeGallery
            id="Recently shared"
            options={{
              bgOpacity: 0.8,
              closeOnScroll: false,
              closeOnVerticalDrag: true,
              pinchToClose: true,
              preload: [1, 2],
              showAnimationDuration: 0,
              showHideOpacity: true,
            }}
          >
            <ul className="pl-12 pr-16 pb-8">
              {loading
                ? cloneElementForSkeletons(<Skeletons />, 3)
                : links.slice(0, recentlySharedLimit).map(linkedItem => {
                    if (isMedia(linkedItem.file)) {
                      return MediaItem(linkedItem);
                    }
                    return ListItem(linkedItem);
                  })}
            </ul>
          </PhotoSwipeGallery>
        </>
      ) : (
        <EmptyState />
      )}
    </div>
  );
};

export default RecentlyShared;
