/* spellchecker: disable */
import {
  IconDefinition,
  faFile,
  faFileAlt,
  faFileArchive,
  faFileAudio,
  faFileCode,
  faFileExcel,
  faFilePdf,
  faFilePowerpoint,
  faFileVideo,
  faFileWord,
} from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type Props = {
  filename?: string;
  mimeType?: string;
  iconSize?: "small" | "medium" | "tiny";
};

// Partially based on:
// https://stackoverflow.com/a/4212908/2570866

const wordMimeTypes = [
  // Microsoft Word
  // .doc .dot
  "application/msword",
  // .doc .dot
  "application/msword-template",
  // .docx
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  // .dotx (no test)
  "application/vnd.openxmlformats-officedocument.wordprocessingml.template",
  // .docm
  "application/vnd.ms-word.document.macroEnabled.12",
  // .dotm (no test)
  "application/vnd.ms-word.template.macroEnabled.12",

  // LibreOffice/OpenOffice Writer
  // .odt
  "application/vnd.oasis.opendocument.text",
  // .ott
  "application/vnd.oasis.opendocument.text-template",
  // .fodt
  "application/vnd.oasis.opendocument.text-flat-xml",
  // .uot
  // NOTE: firefox doesn't know mimetype so maybe ignore
];

const excelMimeTypes = [
  // .csv
  "text/csv",
  // TODO: maybe more data files

  // Microsoft Excel
  // .xls .xlt .xla (no test for .xla)
  "application/vnd.ms-excel",
  // .xlsx
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  // .xltx (no test)
  "application/vnd.openxmlformats-officedocument.spreadsheetml.template",
  // .xlsm
  "application/vnd.ms-excel.sheet.macroEnabled.12",
  // .xltm (no test)
  "application/vnd.ms-excel.template.macroEnabled.12",
  // .xlam (no test)
  "application/vnd.ms-excel.addin.macroEnabled.12",
  // .xlsb (no test)
  "application/vnd.ms-excel.addin.macroEnabled.12",

  // LibreOffice/OpenOffice Calc
  // .ods
  "application/vnd.oasis.opendocument.spreadsheet",
  // .ots
  "application/vnd.oasis.opendocument.spreadsheet-template",
  // .fods
  "application/vnd.oasis.opendocument.spreadsheet-flat-xml",
  // .uos
  // NOTE: firefox doesn't know mimetype so maybe ignore
];

const powerpointMimeTypes = [
  // Microsoft Word
  // .ppt .pot .pps .ppa (no test for .ppa)
  "application/vnd.ms-powerpoint",
  // .pptx
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  // .potx (no test)
  "application/vnd.openxmlformats-officedocument.presentationml.template",
  // .ppsx
  "application/vnd.openxmlformats-officedocument.presentationml.slideshow",
  // .ppam
  "application/vnd.ms-powerpoint.addin.macroEnabled.12",
  // .pptm
  "application/vnd.ms-powerpoint.presentation.macroEnabled.12",
  // .potm
  "application/vnd.ms-powerpoint.template.macroEnabled.12",
  // .ppsm
  "application/vnd.ms-powerpoint.slideshow.macroEnabled.12",

  // LibreOffice/OpenOffice Writer
  // .odp
  "application/vnd.oasis.opendocument.presentation",
  // .otp
  "application/vnd.oasis.opendocument.presentation-template",
  // .fodp
  "application/vnd.oasis.opendocument.presentation-flat-xml",
  // .uop
  // NOTE: firefox doesn't know mimetype so maybe ignore
];

const archiveFileTypes = [
  // .zip
  "application/zip",
  // .z7
  "application/x-7z-compressed",
  // .ar
  "application/x-archive",
  // .tar
  "application/x-tar",
  // .tar.gz
  "application/gzip",
  // .tar.Z
  "application/x-compress",
  // .tar.bz2
  "application/x-bzip",
  // .tar.lz
  "application/x-lzip",
  // .tar.lz4
  "application/x-lz4",
  // .tar.lzma
  "application/x-lzma",
  // .tar.lzo (no test)
  "application/x-lzop",
  // .tar.xz
  "application/x-xz",
  // .war
  "application/x-webarchive",
  // .rar
  "application/vnd.rar",
];

const codeFileTypes = [
  // .html .htm
  "text/html",
  // .css
  "text/css",
  // .js
  "application/x-javascript",
  // .json
  "application/json",
  // .py
  "text/x-python",
  // .go
  "text/x-go",
  // .c
  "text/x-csrc",
  // .cpp
  "text/x-c++src",
  // .rb
  "application/x-ruby",
  // .rust
  "text/rust",
  // .java
  "text/x-java",
  // .php
  "application/x-php",
  // .cs
  "text/x-csharp",
  // .scala
  "text/x-scala",
  // .erl
  "text/x-erlang",
  // .sh
  "application/x-shellscript",
];

const mimeTypeToIconMap: { [key: string]: IconDefinition } = {
  "application/pdf": faFilePdf,
};

for (const type of wordMimeTypes) {
  mimeTypeToIconMap[type] = faFileWord;
}

for (const type of excelMimeTypes) {
  mimeTypeToIconMap[type] = faFileExcel;
}

for (const type of powerpointMimeTypes) {
  mimeTypeToIconMap[type] = faFilePowerpoint;
}

for (const type of archiveFileTypes) {
  mimeTypeToIconMap[type] = faFileArchive;
}

for (const type of codeFileTypes) {
  mimeTypeToIconMap[type] = faFileCode;
}

function mimeTypeToIcon(mimeType?: string) {
  if (mimeType == null) {
    return faFile;
  }

  const icon = mimeTypeToIconMap[mimeType];
  if (icon) {
    return icon;
  }
  if (mimeType.startsWith("audio/")) {
    return faFileAudio;
  }
  if (mimeType.startsWith("video/")) {
    return faFileVideo;
  }
  if (mimeType.startsWith("text/")) {
    return faFileAlt;
  }
  return faFile;
}

function getIconColor(icon: string) {
  switch (icon) {
    case "file-pdf":
      return "text-interactive-alert text-opacity-40";
    case "file-word":
      return "text-interactive-primary text-opacity-40";
    case "file-excel":
      return "text-accent-success text-opacity-40";
    case "file-powerpoint":
      return "text-accent-badge text-opacity-40";
    default:
      return "text-interactive-subtle";
  }
}

function getIconSize(iconSize: Props["iconSize"]) {
  switch (iconSize) {
    case "medium":
      return "text-3xl";
    case "small":
      return "text-title-1";
    case "tiny":
      return "text-body";
  }
}

const FileIcon = ({ mimeType, iconSize = "medium" }: Props): JSX.Element => {
  const icon = mimeTypeToIcon(mimeType);
  const color = getIconColor(icon.iconName);

  return (
    <FontAwesomeIcon
      className={`${getIconSize(iconSize)} ${color}`}
      icon={icon}
    />
  );
};

export default FileIcon;
