import { formatRelativeDate } from "./formatRelativeDate";

type Groups<T extends { createdAt: Date | string }> = {
  group: string;
  items: T[];
};

export const groupItemsByDate = <T extends { createdAt: Date | string }>(
  items: T[]
) => {
  const groups: Groups<T>[] = [];
  items.forEach(i => {
    const date = formatRelativeDate(i.createdAt);
    if (groups.find(g => g.group === date)) {
      groups.find(g => g.group === date)?.items.push(i);
    } else {
      groups.push({ group: date, items: [i] });
    }
  });
  return groups;
};
