import { useCallback, useRef, useState } from "react";

import { nodeAs } from "@utility-types";
import { ModalProps } from "components/ModalKit/Modal";
import { Header, Main } from "components/ModalKit/Parts";
import {
  MemberRole,
  useFetchWorkspaceOrPreviewEdgeQuery,
} from "generated/graphql";
import useFetchMorePendingApprovals from "hooks/pendingApprovals/useFetchMorePendingApprovals";
import useAuthData from "hooks/useAuthData";
import useMemberEdge from "hooks/useMemberEdge";
import breakpoints from "utils/breakpoints";

import { ProfileItemHeader, StandardModal } from "components/Modals";
import { Tabs } from "components/Tabs";

import type { FormRef } from "./WorkspaceModalForm";

import NotAMemberIcons from "components/design-system/NotAMemberIcons";
import { isNative } from "utils/platform";
import WorkspaceAdvanced from "./WorkspaceAdvanced";
import WorkspaceApps from "./WorkspaceApps";
import WorkspaceBilling from "./WorkspaceBilling";
import WorkspaceGroups from "./WorkspaceGroups";
import WorkspaceMembers from "./WorkspaceMembers";
import WorkspaceSettings from "./WorkspaceSettings";

export enum WorkspaceModalTabs { // in order of UI appearance
  Groups = "groups",
  Members = "members",
  Apps = "apps",
  Settings = "settings",
  Billing = "billing",
  Advanced = "advanced",
}

type Props = {
  defaultTab?: WorkspaceModalTabs;
  workspaceID?: string;
} & ModalProps;

const WorkspaceProfileModal = ({
  defaultTab,
  workspaceID,
  ...props
}: Props) => {
  const { authData, authReady } = useAuthData();

  const { data, error, fetchMore, loading } =
    useFetchWorkspaceOrPreviewEdgeQuery({
      fetchPolicy: authReady ? "cache-and-network" : "cache-only",
      nextFetchPolicy: "cache-first",
      skip: !(workspaceID && authData),
      variables: { id: `${workspaceID}-${authData?.me.id}` },
    });

  const { memberEdge, previewEdge } = useMemberEdge(
    nodeAs(data?.node, ["WorkspaceEdge", "WorkspacePreviewEdge"])
  );

  const workspaceEdge = previewEdge || memberEdge;

  const isAdmin = memberEdge?.memberRole === MemberRole.Admin;

  const tabs = [
    {
      tab: WorkspaceModalTabs.Groups,
    },
    {
      tab: WorkspaceModalTabs.Members,
    },
  ];

  if (breakpoints().md) {
    tabs.push({
      tab: WorkspaceModalTabs.Apps,
    });
  }

  if (isAdmin) {
    const adminTabs = [
      { tab: WorkspaceModalTabs.Settings },
      { tab: WorkspaceModalTabs.Advanced },
    ];

    if (breakpoints().md && !isNative()) {
      adminTabs.splice(1, 0, { tab: WorkspaceModalTabs.Billing });
    }

    tabs.push(...adminTabs);
  }
  const [selectedTab, setSelectedTab] = useState<WorkspaceModalTabs>(
    defaultTab || WorkspaceModalTabs.Groups
  );
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);
  const formRef = useRef<FormRef | null>();

  const setFormRef = useCallback((node: FormRef | null) => {
    formRef.current = node;
    setSubmitDisabled(node?.submitDisabled ?? false);
  }, []);

  const onLoadGroups = useCallback(
    async (cursor: string) => {
      fetchMore({ variables: { groupsAfter: cursor } }).catch(err => {
        console.warn("Error: [WorkspaceProfile] - ", err.message);
      });
    },
    [fetchMore]
  );

  // const onLoadMembers = useCallback(
  //   async (cursor: string) => {
  //     fetchMore({ variables: { membersAfter: cursor } }).catch(err => {
  //       console.warn("Error: [WorkspaceProfile] - ", err.message);
  //     });
  //   },
  //   [fetchMore]
  // );

  const pendingApprovals = memberEdge?.node.pendingApprovals;

  useFetchMorePendingApprovals({ fetchMore, pendingApprovals });

  return (
    <StandardModal
      header={
        <Header
          className="!justify-start md:h-48 md:min-h-0 md:pt-16"
          mobileCtaLabel={
            selectedTab === WorkspaceModalTabs.Settings ? "Save" : undefined
          }
          mobileCtaProps={{
            disabled: submitDisabled,
            onClick: () => {
              formRef.current?.submitForm();
            },
          }}
          variant="borderless"
        >
          <ProfileItemHeader target={{ workspace: workspaceEdge?.node }} />
        </Header>
      }
      mdHeightAuto={false}
      {...props}
    >
      <Main className="relative">
        <div className="bg-background-modal sticky top-0 z-1">
          <Tabs<WorkspaceModalTabs>
            buttonClassName="border-b-2 capitalize h-36 mr-24 z-1"
            className="px-16 md:px-32 w-full"
            onClickTab={setSelectedTab}
            selectedTab={selectedTab}
            tabs={tabs}
          />
          <div className="border-b-thin md:border-b-1 border-background-subtle relative -top-1 z-0" />
        </div>

        <div className="px-16 md:px-32">
          {previewEdge ? (
            <div className="join-group" data-testid="JoinGroup">
              <NotAMemberIcons background="bg-background-modal" />
              <span className="text-title">
                You're not a member of this workspace.
              </span>
            </div>
          ) : (
            <>
              {selectedTab === WorkspaceModalTabs.Groups && (
                <WorkspaceGroups
                  loadGroups={onLoadGroups}
                  loading={loading}
                  workspace={memberEdge?.node}
                />
              )}

              {selectedTab === WorkspaceModalTabs.Members &&
                memberEdge?.node && (
                  <WorkspaceMembers
                    workspace={memberEdge.node}
                    isAdmin={isAdmin}
                    hasError={!!error}
                    isLoading={loading}
                    fetchMore={fetchMore}
                    modalId={props.modalId}
                  />
                )}

              {selectedTab === WorkspaceModalTabs.Apps && (
                <WorkspaceApps workspaceEdge={memberEdge} />
              )}

              {selectedTab === WorkspaceModalTabs.Settings && workspaceID && (
                <WorkspaceSettings
                  ref={setFormRef}
                  loading={loading}
                  workspace={memberEdge?.node}
                  {...props}
                />
              )}

              {selectedTab === WorkspaceModalTabs.Billing && workspaceID && (
                <WorkspaceBilling workspaceID={workspaceID} />
              )}

              {selectedTab === WorkspaceModalTabs.Advanced && memberEdge && (
                <WorkspaceAdvanced workspace={memberEdge.node} />
              )}
            </>
          )}
        </div>
      </Main>
    </StandardModal>
  );
};

export default WorkspaceProfileModal;
